import * as React from "react";

import Layout from "../components/layout";
import HeroSection from "../components/OurNetworks/HeroSection";
import Networks from "../components/OurNetworks/Networks";
import Seo from "../components/seo";

import {BrowserView} from 'react-device-detect';

const OurNetworks = () => (
  <Layout>
    <Seo title="Our Networks" />
    {/* <BrowserView>
      <HeroSection />
    </BrowserView> */}
    <Networks />
  </Layout>
);

export default OurNetworks;
